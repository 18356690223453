<template>
  <a
    class="c-splash-page link"
    role="link"
    aria-label="Weiter"
    href="#"
    @click.prevent="goToNextPage"
    @touchend.prevent="goToNextPage"
  >
    <slot></slot>
  </a>
</template>

<script>
import { pageMixin, throttle, EventHub } from '@xpedeo/core'

export default {
  mixins: [pageMixin],
  data () {
    return {
      shouldIgnoreClick: false
    }
  },
  created () {
    console.log('Custom XpSplash screen page', this.pageData)
    this.goToNextPage = throttle(this.goToNextPage)
    // if ()
  },
  mounted () {
    this.$xp.history.addHomePage(
      this.$route.params.id,
      this.$route.path,
      this.pageData.Title,
      0,
      []
    )
    this.$el.focus()
    EventHub.Bus.$on(EventHub.TYPES.CLICKED_HOTSPOT_SPLASHSCREEN, () => {
      console.log(
        'event hub got message CLICKED_HOTSPOT_SPLASHSCREEN and ignoring next click'
      )
      this.shouldIgnoreClick = true
    })
  },
  methods: {
    async goToNextPage (e) {
      console.log('on click gotonextpage', e)
      if (this.shouldIgnoreClick) {
        console.log('ignoring click in splash screen', this.shouldIgnoreClick)
        this.shouldIgnoreClick = !this.shouldIgnoreClick
        return
      }
      // if (this.$xp.content.showPreloadModal) {
      //   return
      // }
      console.log('goToNextPage()')
      if (this.pageData.NextPageID) {
        this.$router.push('/page/' + this.pageData.NextPageID)
      } else {
        console.warn('Splash screen has no next page')
      }
    },
    async checkPermissions () {
      return new Promise(function (resolve, reject) {
        console.log('checkPermissions', process.env.VUE_APP_PLATFORM.toLowerCase())
        let permissions = []
        if (process.env.VUE_APP_PLATFORM.toLowerCase() === 'android') {
          const diagnostic = window.cordova.plugins.diagnostic

          // if (process.env.VUE_APP_IS_LOAN_DEVICE) {
          permissions = [
            // diagnostic.permission.WRITE_EXTERNAL_STORAGE,
            // diagnostic.permission.READ_EXTERNAL_STORAGE,
            diagnostic.permission.ACCESS_FINE_LOCATION,
            diagnostic.permission.ACCESS_COARSE_LOCATION
          ]

          diagnostic.isLocationEnabled(() => {
            console.log('location enabled')
            resolve()
          }, () => {
            console.log('location disabled')
            diagnostic.switchToLocationSettings()
            resolve()
          })
          diagnostic.isGpsLocationAvailable(() => {
            console.log('gps location available')
            resolve()
          }, () => {
            console.log('gps location not available')
            diagnostic.switchToLocationSettings()
            resolve()
          })
          diagnostic.isGpsLocationEnabled(() => {
            console.log('gps location enabled')
            resolve()
          }, () => {
            console.log('gps location disabled')
            diagnostic.switchToLocationSettings()
            resolve()
          })
          // if (window.XpedeoAR) {
          //   permissions.push(diagnostic.permission.CAMERA)
          // }
          // } else {
          // permissions = permissions.map(item => diagnostic.permission[item])
          // const addPermissions = [
          //   diagnostic.permission.WRITE_EXTERNAL_STORAGE,
          //   diagnostic.permission.READ_EXTERNAL_STORAGE
          // ]
          // addPermissions.forEach(element => {
          //   if (!(element in permissions)) {
          //     permissions.push(element)
          //   }
          // })
          // }
          console.log('Checking permissions', permissions)
          permissions.length === 0 && resolve()
          permissions.length && diagnostic.requestRuntimePermissions(
            function (statuses) {
              let grantedCounter = 0
              for (const permission in statuses) {
                switch (statuses[permission]) {
                  case diagnostic.permissionStatus.GRANTED:
                    console.log('Permission granted to use ' + permission)
                    grantedCounter += 1
                    break
                  case diagnostic.permissionStatus.NOT_REQUESTED:
                    console.log('Permission to use ' + permission + ' has not been requested yet')
                    break
                  case diagnostic.permissionStatus.DENIED:
                    console.log('Permission denied to use ' + permission + ' - ask again?')
                    break
                  case diagnostic.permissionStatus.DENIED_ALWAYS:
                    console.log('Permission permanently denied to use ' + permission + ' - guess we won\'t be using it then!')
                    break
                }
              }
              if (grantedCounter === Object.keys(statuses).length) {
                resolve()
              } else {
                reject(new Error(`Permissions were not granted. ${Object.keys(statuses).length} were requested but ${grantedCounter} were granted.`))
              }
            },
            function (error) {
              reject(new Error(error))
            },
            permissions)
        } else {
          resolve()
        }
      })
    }
  },
  watch: {
    shouldIgnoreClick (oldVal, newVal) {
      console.log('shouldIgnoreClick changed', oldVal, newVal)
    },
    '$xp.content.showPreloadModal': {
      handler (v) {
        console.log('showPreloadModal Watcher in splash screen', v)
        this.shouldIgnoreClick = !this.shouldIgnoreClick
      }
    }
  }
}
</script>
